import React from "react";
import { FrontendRoutes } from "./path";

const Index = React.lazy(() =>
  import("../../components/frontend/user/MyMap")
);
const LeafletLocation = React.lazy(() =>
  import("../../components/frontend/user/Locations")
);
const Notfound = React.lazy(() =>
  import("../../components/404")
);

const routes = [
  { path: FrontendRoutes.INDEX, name: "Leaflet Map", component: Index },
  { path: FrontendRoutes.LEAFLET_LOCATION, name: "Leaflet Location with Lat & Long", component: LeafletLocation },
  { path: FrontendRoutes.NOT_FOUND, name: "Not Found", component: Notfound }
];

export default routes;
